import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Heading,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { getUrlParamsRequired } from "../utils/getUrlParams";

import { strings } from "../strings";
import Logo from "./Logo";
import axios from "axios";

I18n.putVocabularies(strings);

const languageOptions = [
  { code: "en", label: "English", template_id: 1969 },
  { code: "fr", label: "Français", template_id: 1901 },
  { code: "pt", label: "Português", template_id: 1968 },
];

function ResetPasswordForm({ isLoading }) {
  const [email, setEmail] = useState("");
  const [advice, setAdvice] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const selectedLanguage = window.localStorage.getItem("selectedLanguage") ||
    (languageOptions.find((option) => option.code === navigator.language) || {}).code;

  const template_id = languageOptions.find((option) => option.code === selectedLanguage)?.template_id;

  const handleClick = async (event) => {
    const url = new URLSearchParams(getUrlParamsRequired).toString();
    const redirect_url = window.location.origin.concat("/login?", url)
    
    event.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_API_URL_PSWD,
        {
          method: "POST",
          body: JSON.stringify({
            email: email,
            env: process.env.REACT_APP_ENV,
            redirect_url: redirect_url,
            template_id: template_id,
        })
        }
      );

      setAdvice(true);
      const url = new URLSearchParams(getUrlParamsRequired).toString();
      setTimeout(() => {
        window.location.href = `/login?${url}`;
      }, 5000);
    } catch (error) {
      setAdvice(true);
      const url = new URLSearchParams(getUrlParamsRequired).toString();
      setTimeout(() => {
        window.location.href = `/login?${url}`;
      }, 4000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View>
      <Card variation="elevated" style={{ width: "350px" }}>
        <Logo />
        <Heading textAlign="center" level={4} paddingTop="1rem">
          {I18n.get("form_field_email_placeholder")}
        </Heading>
        <form onSubmit={handleClick}>
          {advice && (
            <Alert variation="info" isDismissible={false} hasIcon={true}>
              {I18n.get("form_advice")}
            </Alert>
          )}
          <TextField
            marginTop="1rem"
            label={I18n.get("form_field_email")}
            placeholder={I18n.get("form_field_email_placeholder")}
            value={email}
            type="email"
            onChange={handleEmailChange}
            isRequired={true}
            isDisabled={loading || isLoading || advice}
          />
          <Button
            marginTop="1.4rem"
            type="submit"
            size={"small"}
            variation="primary"
            isLoading={loading || isLoading}
            isDisabled={loading || !email || isLoading || advice}
            isFullWidth={true}
          >
            {I18n.get("form_btn_code")}
          </Button>
        </form>
      </Card>
    </View>
  );
}

export default ResetPasswordForm;
