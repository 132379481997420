// components/ResetPasswordChallenge.jsx
import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Heading,
  PasswordField,
  View,
} from "@aws-amplify/ui-react";
import { Auth, I18n } from "aws-amplify";
import Logo from "./Logo";
import PasswordRequirements from "./PasswordRequirements";

const ResetPasswordChallengeForm = ({ user, onPasswordChanged }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError(I18n.get("form_error_passwords_mismatch"));
      return;
    }

    try {
      setLoading(true);
      await Auth.completeNewPassword(user, password).then(() => {
        Auth.signIn(user.username, password);
        window.location.reload();
      });

      onPasswordChanged();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const isPasswordValid = () => {
    const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return regex.test(password);
  };

  return (
    <View>
      <Card variation="elevated" style={{ width: "350px" }}>
        <Logo />
        <Heading textAlign="center" level={4} paddingTop="1rem">
          {I18n.get("form_password_challenge_title")}
        </Heading>
        <form onSubmit={handleSubmit}>
          {error && (
            <Alert variation="error" isDismissible={false} hasIcon={true}>
              {error}
            </Alert>
          )}
          <PasswordField
            marginTop="1rem"
            label={I18n.get("form_field_password_challenge")}
            placeholder={I18n.get("form_field_password_placeholder")}
            value={password}
            onChange={handlePasswordChange}
            isRequired={true}
            isDisabled={loading}
          />
          <PasswordField
            marginTop="1rem"
            label={I18n.get("form_field_password_confirm_challenge")}
            placeholder={I18n.get("form_field_password_confirm_placeholder")}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            isRequired={true}
            isDisabled={loading || !password || !isPasswordValid()}
          />
          <PasswordRequirements password={password} />
          <Button
            marginTop="1.4rem"
            type="submit"
            variation={"primary"}
            loadingText={I18n.get("form_btn_loading")}
            isLoading={loading}
            isDisabled={loading || !password || !confirmPassword}
            isFullWidth={true}
          >
            {I18n.get("form_btn_submit")}
          </Button>
        </form>
      </Card>
    </View>
  );
};

export default ResetPasswordChallengeForm;
