const awsmobile = {
    aws_project_region: "eu-west-3",
    aws_cognito_region: "eu-west-3",
    aws_user_pools_id: "eu-west-3_yKVEaC1n0",
    aws_user_pools_web_client_id: "vhgemlub0mugpok7s3mpnkaur",
    oauth: {
      domain: "identity.viseeon.com.auth.eu-west-3.amazoncognito.com",
    },
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OPTIONAL",
    aws_cognito_mfa_types: ["TOTP"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        "REQUIRES_LOWERCASE",
        "REQUIRES_UPPERCASE",
        "REQUIRES_NUMBERS",
        "REQUIRES_SYMBOLS",
      ],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
  };
  export default awsmobile;